<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23356 aligncenter" src="@/assets/news/13/1.png" alt="图片" width="663" height="411"  sizes="(max-width: 663px) 100vw, 663px"></p>
        <p>10月23日，土耳其总统雷杰普·塔伊普·埃尔多安(Recep Tayyip Erdogan)表示，土耳其一定会实现成为世界十大经济体之一的目标。</p>
        <p>埃尔多安：土耳其肯定会跻身世界前十大经济体行列</p>
        <p>埃尔多安在中部埃斯基谢希尔省（Eskisehir）举行的正义与发展党活动中表示，正义与发展党在过去19年中克服了许多挑战，并将继续娴熟地引导土耳其在2023年迎来100周年。</p>
        <p>他明确表示说：“我们一定会实现成为世界十大经济体之一的目标。我们正在毫不松懈地努力实现这一目标。我们正在国内外努力确保土耳其经济建立在投资、生产、出口和就业的坚实支柱上。”</p>
        <p><strong>伊斯坦布尔机场备受好评</strong></p>
        <p>在国际机场理事会（ACI）举办的第17届ACI欧洲奖上，伊斯坦布尔机场被认为是欧洲机场中的The Best和当之无愧的无障碍机场奖获得者。</p>
        <p>ACI近期发起了“2050年净零排放”运动，目的是针对气候问题采取行动，使机场脱碳。ACI在今年评选欧洲机场中的The Best的标准奖项标准中增加了“复原力和灵感”，并表示他们在评估中侧重于在新冠病毒改进和可持续性领域的激励工作。</p>
        <p>10月26日，作为在瑞士日内瓦举行的ACI欧洲年度大会暨会员大会的一部分，IGA机场运营公司的首席执行官卡德尔·萨姆松鲁（Kadri Samsunlu）被授予该奖项。</p>
        <p>不仅如此，土耳其经济也被大家所看好，吸引很多外资企业，例如，国内的小米、OPPO都纷纷进驻，建厂；很多高净值人群也选择了土耳其身份进行资产配置，相信也会带来新一波的投资热潮。</p>
        <p><img loading="lazy" class="wp-image-23357 aligncenter" src="@/assets/news/13/2.jpg" alt="图片" width="706" height="408"  sizes="(max-width: 706px) 100vw, 706px"></p>
        <p><a href="/turkey"><span style="color: #0000ff;"><strong>土耳其投资入籍项目</strong></span></a><strong><span style="color: #0000ff;">项目优势</span></strong></p>
        <p><strong>高性价比</strong><br>
          仅需投资25万美元起，获永久产权土耳其房产，可合法出租</p>
        <p><strong>赴美跳板</strong><br>
          持土耳其护照，可申请美国E-2签证，1年内登陆美国</p>
        <p><strong>子女教育</strong><br>
          外籍身份有助于孩子以国际生身份就读国内的部分国际学校，及免试入读清华、北大等国内名校*<br>
          *详细规则以学校实时招生政策为准</p>
        <p><strong>出行便利</strong><br>
          持土耳其护照，即可免签112个国家和地区，包括日本、中国香港、新加坡等</p>
        <p><strong>入籍方便快捷</strong><br>
          6-8个月政府审批，无移民监，无语言要求，无管理经验要求及面签要求</p>
        <p><strong>申请要求简单</strong><br>
          无需申报个人资金来源和收入</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news13",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:13,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>